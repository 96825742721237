import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { io } from 'socket.io-client';
import { ChatBoxHeader, ChatBoxInputContainer, ChatBoxStyles, ChatButtonToggler, ChatMessageBox, ChatMessageBoxItemLeft, ChatMessageBoxItemRight, MainChatComponentStyles } from './Chat.styles'
import { faCommentDots, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import TypingLoader from './TypingLoader'
import { CHAT_SERVICE_SOCKET_URL } from '../../config';
import { sendChatMessageConversation, sendChatMessageToken } from './MainChat.socket';
import { chatAxe } from '../../services/axios-client/chatAxeClient';

function MainChatComponent() {
  const [openMessage, setOpenMessage] = useState(false)
  
  const bottomRef = useRef(null);

  const [conversationId, setConversationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');
  const [partialResponse, setPartialResponse] = useState(null);

  const toggleMessage = () => {
    setOpenMessage(!openMessage)
  }

  const sendMessage = async (message) => {
    setMessage('');
    setPartialResponse('');

    const response = await chatAxe.post('/chat/public/message/send', {
      conversationId,
      message,
    });

    if (response.status === 200) {
      setConversationId(response.data.data.conversation_id);
      setMessages([...messages, {
        id: 'new-message',
        from: 'user',
        message,
        created_at: new Date().toISOString(),
      }]);
    }
  };

  const formatMessage = (message) => message.split('\n').map((m) => <div>{m}</div>);

  const onKeyUpHandler = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      sendMessage(e.target.value.trim());
    } else {
      setMessage(e.target.value.trim());
    }
  };

  const selectedMessage = (message) => {
    sendMessage(message.trim());
  };

  useEffect(() => {
    if (conversationId === null) {
      return;
    }

    const options = {
      forceNew: true,
      query: {
        conversationId,
      },
    };

    const s = io(CHAT_SERVICE_SOCKET_URL, options);
    setSocket(s);
  }, [conversationId]);

  useEffect(() => {
    if (socket !== null) {
      socket.off(sendChatMessageToken).on(sendChatMessageToken, ({ token }) => {
        setPartialResponse((prev) => prev + token);
      });

      socket.off(sendChatMessageConversation).on(sendChatMessageConversation, ({ conversation, finishConversation }) => {
        setPartialResponse(null);
        setMessages(conversation);
      });
    }
  }, [socket]);

  useEffect(() => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, partialResponse]);

  const showSendMessage = partialResponse === null;

  const helperOptions = [
    'Was bezweckt der Chatbot?',
    'Warum sollte ich die Custos GmbH beauftragen?',
    'Warum ist Custos ein Legal Tech?',
    'Was unterscheidet Custos von Rechtsanwaltskanzleien?',
    'Was ist die Mission hinter dem Geschäftsmodell?',
    'Wie hoch ist eine Erfolgsbeteiligung?'
  ];

  return (
    <MainChatComponentStyles>
      {openMessage && <ChatBoxStyles id='chatbox' initial={{ opacity: 0, y: -20 }}
        animate={{
          opacity: openMessage ? 1 : 0,
          y: openMessage ? 0 : 50,
          transition: { duration: 0.3 }
        }}>
        <ChatBoxHeader>
          <h6 className='mb-0 text-center text-white'>Welcome To Custos AI</h6>
        </ChatBoxHeader>
        <ChatMessageBox>
        <ChatMessageBoxItemLeft>
          <div className='message-container-left'>
            <p className='mb-0 p-2'>Wie kann ich dir helfen?</p>
          </div>
        </ChatMessageBoxItemLeft>
        {messages.map((m) => {
            if (m.from === 'system') {
              return (
                <ChatMessageBoxItemLeft key={m.id}>
                  <div className='message-container-left'>
                    <p className='mb-0 p-2'>{m.message}</p>
                  </div>
                </ChatMessageBoxItemLeft>
              )
            }
            return (
              <ChatMessageBoxItemRight key={m.id}>
                <div className='message-container-right'>
                  <p className='mb-0 p-2'>{m.message}</p>
                </div>
              </ChatMessageBoxItemRight>
            )})}

            {messages.length === 0 && (
              <>
                <div className="chat-options-helper">Auswählen...</div>

                <div className="chat-options-container">
                  {helperOptions.map((m) => (
                    <div key={m} className="chat-message chat-message-option" onClick={() => selectedMessage(m)}>
                      <div className="chat-message-message">{m}</div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {partialResponse !== null
                && (
                  <ChatMessageBoxItemLeft>
                    {partialResponse !== '' &&
                      <div className='message-container-left'>
                        <p className='mb-0 p-2'>{formatMessage(partialResponse)}</p>
                      </div>
                    }
                  <TypingLoader />
                </ChatMessageBoxItemLeft>
                )}

          <div ref={bottomRef} className="bottom-ref" />
        </ChatMessageBox>
        {showSendMessage === true
              && (
        <ChatBoxInputContainer>
          <div>
            <form>
              <textarea className='form-control' maxLength={500} onKeyUp={onKeyUpHandler} rows="1"/>
              <button className='btn btn-outline-primary' onClick={() => sendMessage(message)}>
                <FontAwesomeIcon icon={faPaperPlane} className='send-btn' />
              </button>
            </form>
          </div>
        </ChatBoxInputContainer>
      )}
      </ChatBoxStyles>}
      <ChatButtonToggler onClick={() => toggleMessage()}>
        <FontAwesomeIcon icon={faCommentDots} size='3x' className='text-color' />
      </ChatButtonToggler>
    </MainChatComponentStyles>
  )
}

export default MainChatComponent