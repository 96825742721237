import React from 'react'
import img from '../../assets/image.jpg'
import image1 from '../../assets/slider-1.webp'
import image2 from '../../assets/slider-2.webp'
import image3 from '../../assets/sliderr.webp'
import image4 from '../../assets/slider-4.webp'
import image5 from '../../assets/slider-5.webp'
import image6 from '../../assets/slider-img-1.webp'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

function Header() {
  return (
    <>
      <div id="carouselExampleCaptions" className="carousel slide d-block d-md-none">
        <div className="carousel-indicators" style={{ zIndex: '999' }}>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className='d-flex align-items-stretch justify-content-center' style={{ 'height': '300px' }}>
              <div className='w-100 d-flex align-items-center justify-content-center position-relative overlay-bg-2'>
                <img src={image2} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                <div className="position-absolute content-index">
                  <h2 className='text-white'>
                    Klar positioniert: <br />
                    Wir beraten <br />
                    ausschließlich <br />
                    Versicherungsnehmer
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item ">
            <div className='d-flex align-items-stretch justify-content-center' style={{ 'height': '300px' }}>
              <div className='w-100 d-flex align-items-center justify-content-center position-relative overlay-bg-2'>
                <img src={image3} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                <div className="position-absolute content-index">
                  <h2 className='text-white'>
                    Unser Anspruch: <br />
                    Die Durchsetzung Ihrer <br />
                    berechtigen Ansprüche
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item ">
            <div className='d-flex align-items-stretch justify-content-center' style={{ 'height': '300px' }}>
              <div className='w-100 d-flex align-items-center justify-content-center position-relative overlay-bg-2'>
                <img src={image4} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                <div className="position-absolute content-index">
                  <h2 className='text-white'>
                    Mit uns agieren Sie auf <br />
                    Augenhöhe mit den<br />
                    Versicherungsgesellschaften
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item ">
            <div className='d-flex align-items-stretch justify-content-center' style={{ 'height': '300px' }}>
              <div className='w-100 d-flex align-items-center justify-content-center position-relative overlay-bg-2'>
                <img src={image5} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                <div className="position-absolute content-index">
                  <h2 className='text-white'>
                    Wir vertreten Ihre <br />
                    Interessen bei Streitwerten <br />
                    ab 100.000 €
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev ms-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <div className="badge bg-white rounded-circle">
            <FontAwesomeIcon icon={faChevronLeft} color='black' size='2xl' />
            <span className="visually-hidden">Previous</span>
          </div>

        </button>
        <button className="carousel-control-next me-2" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <div className="badge bg-white rounded-circle">
            <FontAwesomeIcon icon={faChevronRight} color='black' size='2xl' />
            <span className="visually-hidden">Next</span>
          </div>
        </button>
      </div>
      <div id="carouselExampleCaptions1" className="carousel slide d-none d-md-block">
        <div className="carousel-indicators" style={{ zIndex: '999' }}>
          <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className='d-flex align-items-stretch justify-content-center' style={{ 'height': '500px' }}>
              <div className='w-50 position-relative overlay-bg-1'>
                <img src={image1} className="d-block w-100 h-100 object-fit-cover" alt="..." />
              </div>
              <div className='w-50 d-flex align-items-center justify-content-center position-relative overlay-bg-2'>
                <img src={image2} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                <div className="position-absolute content-index">
                  <h2 className='text-white'>
                    Klar positioniert: <br />
                    Wir beraten <br />
                    ausschließlich <br />
                    Versicherungsnehmer
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item ">
            <div className='d-flex align-items-stretch justify-content-center' style={{ 'height': '500px' }}>
              <div className='w-50 position-relative overlay-bg-1'>
                <img src={image2} className="d-block w-100 h-100 object-fit-cover" alt="..." />
              </div>
              <div className='w-50 d-flex align-items-center justify-content-center position-relative overlay-bg-2'>
                <img src={image3} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                <div className="position-absolute content-index">
                  <h2 className='text-white'>
                    Unser Anspruch: <br />
                    Die Durchsetzung Ihrer <br />
                    berechtigen Ansprüche
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item ">
            <div className='d-flex align-items-stretch justify-content-center' style={{ 'height': '500px' }}>
              <div className='w-50 position-relative overlay-bg-1'>
                <img src={image3} className="d-block w-100 h-100 object-fit-cover" alt="..." />
              </div>
              <div className='w-50 d-flex align-items-center justify-content-center position-relative overlay-bg-2'>
                <img src={image4} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                <div className="position-absolute content-index">
                  <h2 className='text-white'>
                    Mit uns agieren Sie auf <br />
                    Augenhöhe mit den<br />
                    Versicherungsgesellschaften
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item ">
            <div className='d-flex align-items-stretch justify-content-center' style={{ 'height': '500px' }}>
              <div className='w-50 position-relative overlay-bg-1'>
                <img src={image6} className="d-block w-100 h-100 object-fit-cover" alt="..." />
              </div>
              <div className='w-50 d-flex align-items-center justify-content-center position-relative overlay-bg-2'>
                <img src={image5} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                <div className="position-absolute content-index">
                  <h2 className='text-white'>
                    Wir vertreten Ihre <br />
                    Interessen bei Streitwerten <br />
                    ab 100.000 €
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide="prev">
          <div className="badge bg-white rounded-circle">
            <FontAwesomeIcon icon={faChevronLeft} color='black' size='2xl' />
            <span className="visually-hidden">Previous</span>
          </div>

        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide="next">
          <div className="badge bg-white rounded-circle">
            <FontAwesomeIcon icon={faChevronRight} color='black' size='2xl' />
            <span className="visually-hidden">Next</span>
          </div>
        </button>
      </div>

    </>
  )
}

export default Header