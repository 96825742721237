import React from 'react'
import { Outlet } from 'react-router'
import Navbar from '../components/main/Navbar'
import Footer from '../components/main/Footer'
import CookieForm from '../components/CookieForm'
import { useCookies } from 'react-cookie'
import MainChatComponent from '../components/chat/MainChatComponent'

function Layout() {
  const [cookies, setCookie, removeCookie] = useCookies(['custos-cookies']);

  return (
    <div className="container-fluid px-0 position-relative">
      {!cookies['custos-cookies'] && <CookieForm setCookie={setCookie} />}
      <Navbar />
      <Outlet />
      <Footer />
      <MainChatComponent />
    </div>
  )
}

export default Layout