import styled from 'styled-components'
import { motion } from 'framer-motion'


export const MainChatComponentStyles = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999;
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;
`


export const ChatButtonToggler = styled.div`
  cursor: pointer;
  background-color: white;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0px 10px 15px 7px rgba(0,0,0,0.2);
  border: 1px solid #0b2751;
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms linear;

  .text-color {
    color: #0b2751;
    transition: all 300ms linear;
  }


  &:hover {
    background-color: #0b2751;

    .text-color {
    color: #ffffff;

    }
}
`

export const ChatBoxStyles = styled(motion.div)`
  height: 400px;
  width: 300px;
  position: relative;
  right: 40px;
  bottom: 10px;
  background-color: #0b2751;
  margin-bottom: 10px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 10px 15px 7px rgba(0,0,0,0.2);
  transition: all 300ms linear;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &::after {
    position: absolute;
    content: '';
    right: 0;
    bottom: -15px;
    border-right: solid 0px transparent;
    border-left: solid 30px transparent;
    border-top: solid 30px #0b2751;
  }
`

export const ChatBoxHeader = styled.div`
  background-color: #0b2751;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;

`

export const ChatBoxInputContainer = styled.div`
  border-top: 2px solid #0b2751;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px;
  position: relative;
  z-index: 99999;

  form {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 5px;
  }

  `

export const ChatMessageBox = styled.div`
    flex-grow: 1;
    background-color: #ffffff;
    margin: 0;
    padding: 10px 0;
    max-height: 400px;
    overflow: auto;

    .chat-options-helper {
      padding: 5px 10px;
      font-size: 14px;
    }

    .chat-message.chat-message-option {
        width: calc(100% - 40px);
        padding: 5px 10px;
        margin: 5px;
        margin-left: 30px;
        font-size: 14px;
        background-color: rgba(80, 120, 200,.9);
        border-radius: 10px;
        cursor: pointer;
        transition: .3s;

        &:hover {
            background-color: rgba(80, 120, 200,.8);
        }
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #0b2751;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #5892E8;
    }
  `

export const ChatMessageBoxItemLeft = styled.div`
  .message-container-left{
    width: auto;
    display: table;
    margin-right: 35px;
    background-color: #5892E8;
    height: 30px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 10px;

    p {
      padding-left: 10px !important;
      font-size: 14px;
      line-height: 18px;
    }
  }


`

export const ChatMessageBoxItemRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .message-container-right {
    width: auto;
    display: table;
    margin-right: 10px;
    background-color: #6BFF8D;
    height: 30px;
    margin-left: 35px;
    border-radius: 10px;

    p {
      padding-left: 10px !important;
      font-size: 14px;
      line-height: 18px;
    }  
  }

`

export const TypingLoaderStyles = styled.div`
  &.loader, &.loader:before, &.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
  }
  &.loader {
    margin-bottom: 20px;
    margin-left: 30px;
    color: #0b2751;
    font-size: 6px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  &.loader:before,
  &.loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  &.loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  &.loader:after {
    left: 3.5em;
  }

  @keyframes bblFadInOut {
    0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
    40% { box-shadow: 0 2.5em 0 0 }
  }
    
`